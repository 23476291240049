import Vue from "vue";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCjn6IIveDoF88zNNMfhZeXawSO77RKD3g",
  authDomain: "yaukeeengltd.firebaseapp.com",
  databaseURL: "https://yaukeeengltd-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "yaukeeengltd",
  storageBucket: "yaukeeengltd.appspot.com",
  messagingSenderId: "230690397485",
  appId: "1:230690397485:web:3c18faf88dc4ae01c2c7a4",
  measurementId: "G-ZSJGEMX1GF"
};




Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "YAU KEE ENGINEERING COMPANY LIMITED"; //公司名稱
      },
      get regFormDomain() {
        return "https://form.yaukeeeng.xyz"; 
      },
    };
  },
});
//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}
